import React from "react"
import { useContext, useReducer } from "react"
import { Link } from "gatsby"
import styles from "./navigation.module.css"
import { v4 as uuidv4 } from "uuid"

export const NavContext = React.createContext([{}, () => {}])

function reducer(state, action) {
  switch (action.type) {
    case "toggle":
      return { display: !state.display }
    default:
      throw new Error()
  }
}

export const NavProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { display: false })
  return (
    <NavContext.Provider value={[state, dispatch]}>
      {children}
    </NavContext.Provider>
  )
}

const navItems = [
  {
    copy: "Blog",
    slug: "/",
  },
  {
    copy: "Newsletter",
    slug: "/newsletter",
  },
  {
    copy: "About",
    slug: "/about",
  },
  // {
  //   copy: "Feed",
  //   slug: "/feed.xml",
  // },
  {
    copy: "GitHub",
    slug: "https://www.github.com/jsfour",
  },
  {
    copy: "LinkedIn",
    slug: "https://www.linkedin.com/in/jsfour",
  },
  {
    copy: "Medium",
    slug: "https://medium.com/@jsfour/",
  },
  {
    copy: "Twitter",
    slug: "https://www.twitter.com/jsfour",
  },
  {
    copy: "Podcast",
    slug: "/podcast",
  },
  {
    copy: "Sitemap",
    slug: "/sitemap.xml",
  },
].sort((a, b) => {
  return a.copy.toUpperCase().localeCompare(b.copy.toUpperCase())
})

const LinkSelector = ({ children, href, className, onClick }) => {
  if (href[0] !== "/" || href.includes(".xml")) {
    return (
      <a href={href} className={className} onClick={onClick}>
        {children}
      </a>
    )
  }
  return (
    <Link to={href} className={className} onClick={onClick}>
      {children}
    </Link>
  )
}

const Navigation = ({ heading }) => {
  const [state, dispatch] = useContext(NavContext)
  let className = styles.hiddenNav
  if (state.display) {
    className = styles.nav
  }

  const closeNav = () => {
    dispatch({ type: "toggle" })
  }

  return (
    <div className={className} onClick={closeNav}>
      <svg
        className="mx-auto h-8 w-8 mt-8 fill-current text-gray-800 mx-auto cursor-pointer hover:text-yellow-500"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" />
      </svg>
      <p>{heading}</p>
      {/* <p onClick={closeNav}> Close </p> */}
      <div>
        {navItems.map((item, idx) => {
          return (
            <LinkSelector href={item.slug} key={uuidv4()}>
              {item.copy}
            </LinkSelector>
          )
        })}
      </div>
    </div>
  )
}

export default Navigation
